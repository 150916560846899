<template>
  <div class="pagePadding">
    <div class="pageTop">
      <Form inline class="formMarginBtm20 clearfix">
        <FormItem>
          <span>供应商名称：</span>
          <Select class="iviewIptWidth250 marginRight60" v-model="detailsInfo.supplier_id" :disabled="noChange || type == '1'" @on-change="supplier_idSelect">
            <Option v-for="(item, index) in supplierList" :value="item.supplier_id" :key="item.supplier_id + index">{{ item.supplier_name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span>收货仓库：</span>
          <Select class="iviewIptWidth250" v-model="detailsInfo.warehouse_id" :disabled="type == '1'" clearable>
            <Option v-for="item in storeList" :value="item.warehouse_id" :key="item.warehouse_id">{{ item.warehouse_name }}</Option>
          </Select>
        </FormItem>
      </Form>
      <Table :columns="listColumns" :data="meterList" @on-selection-change="selectChange" border :loading="loading">
        <template slot-scope="{ row, index }" slot="unit_price">
          <div class="danjia">
            <span class="fl">¥ </span>
            <!--            <i-input class="fl" placeholder="请输入" :value="row.unit_price" maxlength='8' style="width: 100px;" @on-change="changeIpt($event, 'unit_price', index)"></i-input>-->
            <InputNumber :disabled="row._disabled" :max="999999999.99" :min="0" :precision="2" v-model="row.unit_price" :active-change="false" style="width: 100px;" @on-change="changeTotal($event, 'unit_price', index)"></InputNumber>
          </div>
        </template>
        <template slot-scope="{ row, index }" slot="num">
          <!--          <i-input placeholder="请输入" type="number" :value="row.number" @on-change="changeIpt($event, 'number', index)"></i-input>-->
          <InputNumber :disabled="row._disabled" :max="999999" :min="0" :precision="0" v-model="row.number" :active-change="false" style="width: 100px;" @on-change="changeTotal($event, 'number', index)"></InputNumber>
        </template>
      </Table>
      <div class="summary" v-if="totleMoney > 0">
        <div>
          <span style="margin-right: 20px;"
            >总数量：<span class="color389">{{ allNum }}</span></span
          >
          <span>合计金额：</span>
          <span class="color389">¥{{ totleMoney }}</span>
        </div>
      </div>
      <div class="tipsDiv">
        <div class="tipsFl">备注</div>
        <div class="tipsFr">
          <i-input type="textarea" maxlength="100" show-word-limit placeholder="请输入" v-model="detailsInfo.note"></i-input>
        </div>
      </div>
    </div>
    <div class="pageBtm clearfix">
      <span class="pageBtn finger btnReset marginLeft20 fr" v-if="type == 2" @click="save(1)">提交</span>
      <span class="pageBtn finger btnSure fr marginLeft20" v-if="type == 2" @click="save(0)">保存</span>
      <span class="pageBtn finger btnReset fr" @click="back">返回</span>
    </div>
    <!--    删除产品-->
    <tips-component showModal="delete" v-if="deleteStatus" @cancleBtn="deleteStatus = false" @sureBrn="sureDelete"></tips-component>
    <!--    提交-->
    <tips-component showModal="post" v-if="postStatus" @cancleBtn="postStatus = false" @sureBrn="surePost"></tips-component>
  </div>
</template>

<script>
import TipsComponent from '@/components/tipsComponent'
export default {
  name: 'editPurchaseRequisitionConsign',
  components: {
    TipsComponent,
  },
  computed: {
    allNum() {
      let totle = 0
      this.meterList.forEach((e, i) => {
        totle = totle * 1 + e.number * 1
      })
      return totle
    },
    totleMoney() {
      let totle = 0
      this.meterList.forEach((e, i) => {
        totle = totle * 1 + e.number * e.unit_price
      })
      return totle.toFixed(2)
    },
  },
  data() {
    return {
      // 勾选后待提交数组
      saveList: [],
      deleteStatus: false,
      postStatus: false,
      storeList: [],
      supplierList: [],
      meterList: [],
      listColumns: [
        // {
        //   type: 'selection',
        //   width: 55,
        //   align: 'center',
        // },
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '物料号',
          key: 'product_model_code',
          align: 'center',
          minWidth: 115,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 115,
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
          minWidth: 180,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 115,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          width: 115,
        },
        {
          title: '单价',
          slot: 'unit_price',
          align: 'center',
          width: 140,
        },
        // {
        //   title: '税率',
        //   key: 'tax_rate',
        //   align: 'center',
        //   width: 140,
        // },
        {
          title: '不含税价',
          key: 'tax_rate_price',
          align: 'center',
          width: 140,
          render: (h, param) => {
            return h('div', [h('span', (param.row.unit_price/(1+(param.row.tax_rate?param.row.tax_rate:0)/100)).toFixed(4))])
          },
        },


        {
          title: '数量',
          slot: 'num',
          align: 'center',
          width: 140,
        },
        {
          title: '库存余量',
          key: 'inventory_quantity',
          align: 'center',
          width: 100,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 85,
        },
        {
          title: '包装规格',
          key: 'packing_size',
          align: 'center',
          width: 114,
        },
        {
          title: '有效天数',
          key: 'valid_days',
          align: 'center',
          width: 140,
        },
        {
          title: '小计',
          align: 'center',
          width: 100,
          render: (h, param) => {
            return h('div', [h('span', param.row.total ? '¥' + param.row.total : '0')])
          },
        },
        {
          title: '操作',
          align: 'center',
          width: 100,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.deleteRow(param.index)
                    },
                  },
                },
                this.type == 1 ? '-' : '删除'
              ),
            ])
          },
        },
      ],
      id: '', // 编辑的采购单id
      postFrom2: {}, // 用来存放提交需要调用新接口的穿参数
      postFrom: {
        supplier_id: '',
        line_info: [],
        note: '',
      },
      detailsInfo: {},
      type: 1, // 1 是查看 2是编辑
      noChange: false,
      loading: true
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.type = this.$route.query.type
    this.queryDetails(this.id)
    let select = {
      type: 'selection',
      width: 55,
      align: 'center',
    }
    // 如果是转单就添加 勾选
    if (this.$route.query.state == '转单') {
      this.listColumns.unshift(select)
    }
  },
  methods: {
    // 再次调起供应商详情
    supplier_idSelect(e) {
     if(!e) {
       return
     }
      this.$http
        .get(this.$apiConsign.pruchaseApplyDetail, { id: this.id,supplier_id:e }, true)
        .then(res => {
          // this.detailsInfo = res.data
          this.meterList = res.data.purchase_apply_line
          this.meterList.forEach((item, i) => {
            if (item.is_transfer) {
              item._disabled = true
            }
            this.meterList[i].total = this.meterList[i].number * this.meterList[i].unit_price
          })
          // state=转单
          if (res.data.supplier_id && this.$route.query.state != '转单') {
            this.noChange = true
          } else {
            this.noChange = false
          }
        })
    },
    selectChange(list) {
      this.saveList = list
      console.log(this.saveList)
    },
    back() {
      this.$router.go(-1)
    },
    // 保存
    save(num) {
      if (!this.detailsInfo.warehouse_id) {
        this.$Message.warning('请选择仓库')
        return
      }
      // 如果是转单状态 并且是 点击提交的时候才走这一步
      if (this.$route.query.state == '转单' && num == 1) {
        if (this.saveList.length === 0) {
          this.$Message.warning('请至少选择一组采购申请单')
          return
        }
        for (let i = 0; i < this.saveList.length; i++) {
          // 不含税单价
            this.saveList[i].tax_rate_price = this.saveList[i].unit_price/(1+(this.saveList[i].tax_rate?this.saveList[i].tax_rate:0)/100)
          this.saveList[i].name = this.saveList[i].product_name
          if (!this.saveList[i].number) {
            this.$Message.warning(`请完善第${i + 1}组产品的单价或者数量`)
            return
          }
        }
        if (!this.detailsInfo.supplier_id) {
          this.$Message.warning('请选择供应商')
          return
        }
        // line_info里面传递的是勾选的数据
        this.postFrom2.line_info = JSON.parse(JSON.stringify(this.saveList))
        this.postFrom2.supplier_id = this.detailsInfo.supplier_id
        this.postFrom2.order_number = this.$route.query.order_number
        this.postFrom2.warehouse_id = this.detailsInfo.warehouse_id
        this.postFrom2.note = this.detailsInfo.note
      } else {
        for (let i = 0; i < this.meterList.length; i++) {

          this.meterList[i].name = this.meterList[i].product_name
          if (!this.meterList[i].number) {
            this.$Message.warning(`请完善第${i + 1}组产品的单价或者数量`)
            return
          }
        }
      }
      this.postFrom.line_info = JSON.parse(JSON.stringify(this.meterList))
      this.postFrom.supplier_id = this.detailsInfo.supplier_id
      this.postFrom.id = this.id
      this.postFrom.warehouse_id = this.detailsInfo.warehouse_id
      this.postFrom.note = this.detailsInfo.note
      // 如果是提交就return ； 如果是保存就走下面的接口
      if (num) {
        this.postFrom.is_submit = '1'
        this.postStatus = true
        return
      }
      this.$http.put(this.$apiConsign.pruchaseApplyManage, this.postFrom, true).then(res => {
        this.$Message.success('保存成功！')
        this.$router.go(-1)
      })
      sessionStorage.setItem('updataCache','0')
    },
    // 最终确认提交
    surePost() {
      this.postStatus = false
      // 如果提交的时候状态是 转单就调用转单
      if (this.$route.query.state == '转单') {
        this.$http.post(this.$apiConsign.purchaseApplyOperate, this.postFrom2, true).then(res => {
          this.$http.put(this.$apiConsign.pruchaseApplyManage, this.postFrom, true).then(res => {
            this.$Message.success('提交成功！')
            this.$router.go(-1)
          })
        })
      } else {
        // this.$http.post(this.$apiConsign.purchaseApplyOperate, this.postFrom2, true).then(res => {
        //
        // })
        this.$http.put(this.$apiConsign.pruchaseApplyManage, this.postFrom, true).then(res => {
          this.$Message.success('提交成功！')
          this.$router.go(-1)
        })
      }
      sessionStorage.setItem('updataCache','0')
    },
    // 确认删除
    sureDelete() {
      this.meterList.splice(this.clickIndex, 1)
      this.deleteStatus = false
      this.$Message.success('删除成功')
      console.log(this.meterList, '删除')
    },
    // 点击删除按钮
    deleteRow(index) {
      if (this.meterList.length == 1) {
        this.$Message.warning('至少保留一个产品')
        return
      }
      this.clickIndex = index
      this.deleteStatus = true
    },
    // 查询仓库列表
    queryList() {
      this.isLoad = true
      let data = {
        warehouse_name: '',
        page: 1,
        rows: 1000,
        is_consign:1
      }
      this.$http.get(this.$apiConsign.permissionWarehouseList, data, true).then(res => {
        this.storeList = res.data
      })
    },
    // 供应商名称
    querySupplierSelect() {
      if (this.$route.query.state == '转单') {
        this.$http.get(this.$apiConsign.purchaseApplySupplier, { apply_id: this.$route.query.id }, true).then(res => {
          this.supplierList = res.data
        })
      } else {
        this.$http.fetch(this.$apiConsign.supplierSelect, true).then(res => {
          this.supplierList = res.data
        })
      }
    },
    changeTotal(e, name, index) {
      this.$set(this.meterList[index], name, e * 1)
      let total = this.meterList[index].number * this.meterList[index].unit_price
      this.$set(this.meterList[index], 'total', total > 0 && total ? total.toFixed(2) : 0)
    },
    // 改变数据
    changeIpt(e, name, index) {
      if (e.target.value * 1 < 0) {
        this.$set(this.meterList[index], name, 1)
      } else {
        this.$set(this.meterList[index], name, e.target.value.trim() * 1)
      }
      let total = this.meterList[index].number * this.meterList[index].unit_price
      this.$set(this.meterList[index], 'total', total > 0 ? total.toFixed(2) : 0)
    },
    // 查询详情
    queryDetails(id) {
      this.loading = true
      this.$http
        .get(this.$apiConsign.pruchaseApplyDetail, { id: id }, true)
        .then(res => {
          this.detailsInfo = res.data
          this.meterList = res.data.purchase_apply_line
          this.loading = false
          this.meterList.forEach((item, i) => {
            if (item.is_transfer) {
              item._disabled = true
            }
            this.meterList[i].total = this.meterList[i].number * this.meterList[i].unit_price
          })
          // state=转单
          if (res.data.supplier_id && this.$route.query.state != '转单') {
            this.noChange = true
          } else {
            this.noChange = false
          }
        })
        .then(() => {
          this.querySupplierSelect()
          this.queryList()
        })
    },
  },
}
</script>

<style scoped lang="less">
.pagePadding {
  display: flex;
  flex-direction: column;
  .pageTop {
    flex: 1;
    overflow-y: auto;
    .tipsDiv {
      margin-top: 20px;
      border: 1px solid #e8eaec;
      height: 230px;
      display: flex;
      .tipsFl {
        width: 210px;
        line-height: 230px;
        padding-left: 33px;
        border-right: 1px solid #e8eaec;
        background: #f8f8f9;
        color: #525b6d;
        font-size: 14px;
      }
      /deep/ .tipsFr {
        flex: 1;
        display: flex;
        padding: 8px 15px 8px 23px;
        .ivu-input-wrapper {
          height: 100% !important;
        }
        .ivu-input {
          height: 100% !important;
        }
      }
    }
  }
  .pageBtm {
    float: right;
    padding-top: 25px;
  }
}
.marginRight60 {
  margin-right: 60px;
}
.marginRight50 {
  margin-right: 55px;
}
.danjia {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
